html body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overflow-x: hidden;
}

:root {
  --off-white: #c1f7e8;
}

.nav-content {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.nav {
  background-color: var(--off-white);
  position: sticky;
  top: 0px;
  width: 100vmax;
  height: 80px;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.nav-logo {
  display: flex;
  align-items: center;

  height: 75px;
  width: 305px;
}

.nav-logo:hover {
  cursor: pointer;
}

.nav .nav-content {
  position: relative;
  max-width: 1100px;
  padding: 0rem 3rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-items {
  margin: 0;
}

.nav-item {
  display: inline-block;
  margin: 0 12px;
  padding: 0 8px;
  color: #333;
  height: 80px;
  line-height: 80px;
}

.nav-item:hover {
  color: black;
  cursor: pointer;
}

.nav-item > .active {
  font-weight: bold;
  border-bottom: 1px solid #333;
}

/*navbar dropdown-menu */

.dropdown-menu {
  display: flex;
  justify-content: space-around;

  position: absolute;
  overflow: hidden;

  left: 0;
  height: 0;
  width: 100%;

  padding: 0rem 4rem;
  top: 80px;

  opacity: 0;

  transition: height 0.4s ease-in-out, padding 0.4s ease-in-out, opacity 0.6s, width 0.1s;
}

.dropdown:hover .dropdown-menu {
  height: 20rem;
  opacity: 1;
  padding: 1rem 4rem;
}

.dm-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0;
  width: 20rem;
  border-radius: 1rem;
  font-size: 0.9rem;

  color: #333;

  background: rgba(250, 250, 250, 0.98);

  transition: 0.3s;
}

.dm-item:hover {
  padding: 1rem 2.5rem;
  margin: -17px -43px;
}

.dm-item-title {
  font-size: 26px;
}

.dm-item-img {
  max-width: 300px;
}

.hero {
  visibility: hidden;
  height: calc(100vh - 90px);
}
.visable {
  visibility: visible !important;
}
.other-margin {
  margin-top: 160px;
}

.hero .container {
  width: 100%;
  min-width: 1440px;
  margin: 0 auto;
}
.hero .container .hero-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px;
  height: calc(100vh - 90px);
}
.hero .container .hero-inner .hero-content {
  width: 50%;
}
.hero .container .hero-inner .hero-content .hero-content-inner {
  width: 400px;
  color: #323232;
  margin: 0 auto;
}
.hero .container .hero-inner .hero-content .hero-content-inner h1 {
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 24px;
}
.hero .container .hero-inner .hero-content .hero-content-inner h1 .hero-content-line {
  margin: 0;
  height: 44px;
  overflow: hidden;
}
.hero .container .hero-inner .hero-content .hero-content-inner p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  padding-right: 48px;
  margin-bottom: 56px;
}
.hero .container .hero-inner .hero-content .hero-content-inner .btn-row {
  position: relative;
}
.hero .container .hero-inner .hero-content .hero-content-inner .btn-row:before {
  position: absolute;
  content: '';
  width: 56px;
  height: 1px;
  background: #dfdfdf;
  top: 50%;
  left: -68px;
}
.hero .container .hero-inner .hero-content .hero-content-inner .btn-row button {
  background: none;
  border: none;
  font-size: 12px;
  padding: 0;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.7px;
  display: flex;
  align-items: center;
  position: relative;
}
.hero .container .hero-inner .hero-content .hero-content-inner .btn-row button:focus {
  outline: none;
}
.hero
  .container
  .hero-inner
  .hero-content
  .hero-content-inner
  .btn-row
  button
  .arrow-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #007fff;
  height: 48px;
  width: 48px;
  border-radius: 100px;
  margin-left: 16px;
  left: 64px;
  transform-origin: center center;
}
.hero .container .hero-inner .hero-images {
  width: 50%;
  height: 100vh;
  position: relative;
}
.hero .container .hero-inner .hero-images .hero-images-inner .hero-image {
  position: absolute;
  overflow: hidden;
}
.hero .container .hero-inner .hero-images .hero-images-inner .hero-image.girl {
  top: 25%;
  right: 25%;
  width: 650px;
  height: 650px;
}
.hero .container .hero-inner .hero-images .hero-images-inner .hero-image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

/* bottom OtherSevices btnNavbar */

.btn-nav {
  position: fixed;

  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;

  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
}

.nav-trigger {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;

  background-color: rgba(0, 0, 0, 0.35);

  border-style: none;
  cursor: pointer;
}

.nav-trigger:focus,
.btn-nav:hover .nav-trigger {
  backdrop-filter: blur(5rem);
  opacity: 0.7;
}

.btn-nav-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  opacity: 0;
  visibility: hidden;

  width: 0;
  height: 0;
  margin-bottom: 1rem;
  padding: 1rem 1rem 3rem 1rem;
  border-radius: 1rem;

  transition: width 0.7s, height 0.35s, opacity 0.2s;
}

.nav-trigger:focus + .btn-nav-items,
.btn-nav:hover .btn-nav-items {
  visibility: visible;
  opacity: 1;

  width: 20rem;
  height: clamp(312px, calc(70vh + 32px), 552px);
}

.btn-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #333;
  font-size: 0.9rem;

  height: 32%;
  border-radius: 1rem;
  background: rgba(250, 250, 250, 0.98);

  transition: 0.45s;
}

.btn-nav-item:hover {
  padding: 8px;
  margin: -6px;
}
