@import url('https://fonts.googleapis.com/css?family=Oswald:300|Roboto:300&display=swap');

img {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  border-style: none;
  height: auto;
}

a img {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  outline: none;
}

figure {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  display: block;
  background-color: #fff;
  -ms-interpolation-mode: bicubic;
}

figure img {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  display: block;
  width: auto;
  max-width: 100%;
}

/**
 * Variables 
 */
:root {
  --master-color: #c19f63;
  --master-color-partner-dark: #7c591d;

  --headings-color: #263358;

  --main-bg-color: #fff;
  --secondary1-bg-color: #f6f6f6;
  --secondary2-bg-color: #fff0ec;

  --main-txt-color: #333;
  --secondary-txt-color: #757575;

  --sub-color: #b4b4b4;
}

/** 
 * Typography 
 */
.blog-container {
  font-family: 'Roboto', sans-serif;
  font-size: 112.5%;
  font-style: normal;
  font-weight: 300;
  line-height: 1.6em;

  color: var(--main-txt-color);
  background: var(--main-bg-color);
}

h1,
h2 {
  display: block;
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4em;
  color: var(--headings-color);
}

h1 {
  font-size: calc(24px + 2vw);
  margin: 1.2em 0;
}

h2 {
  font-size: calc(22px + 0.8vw);
}

h1 a,
h2 a {
  font-weight: 300;
  text-decoration: none;
  color: var(--headings-color);
  background-color: transparent;
  outline: none;
}

a:hover {
  color: var(--master-color-partner-dark);
}

a:focus {
  color: var(--headings-color);
  background-color: var(--secondary2-bg-color);
}

/**
 * Page Layout
 */
html,
body {
  height: 100%;
}

.blog-container {
  min-height: 100vh;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1.5em;
  overflow: hidden;
}

/**
 * Post List Layout
 */
.post-list_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
}

.post-list__post {
  display: grid;
  grid-template-areas:
    'header'
    'footer'
    'pr_img';
  grid-auto-rows: 1fr auto auto;
  align-items: start;
  grid-gap: 1em 0;
}

.post-list__post:nth-child(2n + 1) {
  background-color: var(--secondary1-bg-color);
}

.post-list__post:nth-child(3n + 2) {
  grid-template-areas:
    'pr_img'
    'header'
    'footer';
  grid-auto-rows: auto 1fr auto;
}

.post__preview-img {
  grid-area: pr_img;
}

.post__header {
  grid-area: header;
}

.post__meta {
  grid-area: footer;
  align-self: start;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/**
 * Post List Design
 */
.post-list {
  margin-bottom: 4em;
}

.post {
  position: relative;
  padding: 1.6em;
}

.post:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 3px var(--master-color) solid;
  border-image: linear-gradient(110deg, #c19f63, #c6972b, #f5cd87, #c6972b, #c19f63) 1;
  z-index: 2;
  opacity: 0;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.post:hover:after {
  opacity: 1;
}

.post > * {
  z-index: 4;
  position: relative;
}

.post__preview-img {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.post__preview-img img {
  width: 100%;
  height: auto;
  position: absolute;
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
  margin: auto;
}

.post__time {
  color: var(--sub-color);
}

.post__meta a {
  color: var(--sub-color);
  text-decoration: none;
  border-bottom: 1px var(--sub-color) solid;
}

.post__meta a:hover {
  color: var(--master-color-partner-dark);
}

.post__comments {
  position: relative;
  padding-left: 24px;
}

.post__comments:before,
.post__comments:after {
  content: '';
  display: block;
  position: absolute;
  border: 1px var(--sub-color) solid;
}

.post__comments:before {
  width: 16px;
  height: 11px;
  border-radius: 50%;
  left: 0;
  top: calc(50% - 8px);
  z-index: 1;
}

.post__comments:after {
  width: 3px;
  height: 6px;
  border-width: 0 1px 1px 0;
  transform: rotate(31deg);
  top: 50%;
  left: 11px;
  z-index: 2;
  background-color: var(--secondary1-bg-color);
}

/**
 * Breakpoint 900px
 */
@media all and (max-width: 900px) {
  .post-list_grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .post-list__post:nth-child(n) {
    background-color: transparent;
    grid-template-areas:
      'header'
      'footer'
      'pr_img';
    grid-auto-rows: 1fr auto auto;
  }

  .post-list__post:nth-child(4n),
  .post-list__post:nth-child(4n + 1) {
    background-color: var(--secondary1-bg-color);
  }

  .post-list__post:nth-child(even) {
    grid-template-areas:
      'pr_img'
      'header'
      'footer';
    grid-auto-rows: auto 1fr auto;
  }
}

/**
 * Breakpoint 670px
 */
@media all and (max-width: 670px) {
  .post-list_grid {
    grid-template-columns: 1fr;
  }

  .post-list__post:nth-child(n) {
    background-color: var(--secondary1-bg-color);
    grid-template-areas:
      'header'
      'footer'
      'pr_img';
    grid-auto-rows: 1fr auto auto;
  }

  .post-list__post:nth-child(even) {
    background-color: transparent;
  }
}
