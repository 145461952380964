$animationSpeed: 40s;
$height: 200px;

.partners-container {
  align-items: center;
  background: white;
  display: flex;
  height: $height;
  justify-content: center;
  margin-bottom: 60px;
}

@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

// Styling
.slider {
  background: white;
  height: $height;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 960px;

  &::before,
  &::after {
    @include white-gradient;
    content: '';
    height: $height;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(250px * 14);
    gap: 21px;
  }

  .slide {
    height: $height;
    width: 250px;
  }
}
