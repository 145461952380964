@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,600,700');
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700');

.heading {
  font-family: Montserrat, sans-serif;
}

.header .subheading {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: smaller;
  color: #455a64;
}

.poster-image {
  width: 100%;
  object-fit: cover;
}

.heading {
  margin: 0;
  font-weight: 600;
}

.name-link {
  color: #ffffff;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .post .container {
    grid-template-columns: 2rem 3fr 1fr 2rem;
  }
  .post .content {
    grid-column: 2 / 4;
  }
  .post .aside {
    grid-column: 2 / 4;
  }
}

.post .card {
  height: 5rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  margin: 1rem 0;
  cursor: pointer;
}

.post .card img {
  height: 100%;
  width: 40%;
  margin-right: 0.5rem;
}

.post .card p {
  margin: 0;
}

.post .card .title {
  font-size: 0.8rem;
}

.post .card .author {
  font-size: small;
}
